@media (max-width: 1680px) {
  .logo_and_text {
    gap: 20px;

    img {
      width: 70px;
      height: 70px;
    }

    .text {
      img {
        width: 250px;
        height: 60px;
      }
    }
  }

  .content_below_logo {
    .content_headingText {
      h1 {
        font-size: 32px;
        line-height: 40px;
      }

      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .card_login {
      width: 364px;
      padding: 16px 16px;

      .card_header {
        font-size: 22px;
        margin-bottom: 12px;
      }

      .card_text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      button {
        padding: 6px 12px;
      }
    }

    .card_right_section {
      h1 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
        margin-top: 6px;
        line-height: 16px;
      }
    }

    .bottom_section {
      font-size: 16px !important;
      line-height: 24px;
      margin-top: 32px;
    }
  }

  .w-880 {
    width: 880px;
  }
}

@media (max-width: 1440px) {
  .w-880 {
    width: 880px;
  }
}

@media (max-width: 1365px) {

  //MAC air
  .logo_and_text {
    gap: 20px;

    img {
      width: 70px;
      height: 70px;
    }

    .text {
      img {
        width: 250px;
        height: 60px;
      }
    }
  }

  .content_below_logo {
    .content_headingText {
      h1 {
        font-size: 32px;
        line-height: 40px;
      }

      h2 {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .card_login {
      width: 364px;
      padding: 16px 16px;

      .card_header {
        font-size: 22px;
        margin-bottom: 12px;
      }

      .card_text {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      button {
        padding: 6px 12px;
      }
    }

    .card_right_section {
      h1 {
        font-size: 16px;
      }

      p {
        font-size: 12px;
        margin-top: 6px;
        line-height: 16px;
      }
    }

    .bottom_section {
      font-size: 16px !important;
      line-height: 24px;
      margin-top: 32px;
    }
  }

  .w-880 {
    width: 880px;
  }
}

@media (max-width: 1279px) {
  .w-880 {
    width: 880px;
  }
}

@media (max-width: 1024px) {
  .w-880 {
    width: 780px;
  }
  .dashboard_card_main {
    width: 100%;
    height: 100%;
  }

  .card_title {
    font-size: 21px;
  }

  .card_description {
    font-size: 14px;
  }
}

@media (max-width: 1023px) {
  .w-880 {
    width: 600px;
  }

  .login_left_panel_initial {
    padding: 5%;
  }

  .login_left_panel_internal_box {
    width: calc(100%);
  }

  .logo_and_text {
    gap: 20px;
    margin-bottom: 10px;
  }


}

@media (max-width: 642px) {

  .map_toggle_button {
    width: 100%;

    .single_button {
      width: 100%;
    }
  }

  .border-right-only {
    border-right: 0px;
  }

  .image-input-info {
    width: 100%;
  }

  .csvButtonRepoting {
    width: 100%;
  }

  .selectColumnsButton {
    width: 100%;
  }

  .w-siderbar {
    width: 55px;
  }

  .w-880 {
    width: 600px;
  }

  .dashboard_card_inner_right {
    padding: 0px 0px 0px 10px;
  }

  .card_title {
    font-size: 20px;
  }

  .card_description {
    font-size: 12px;
  }

  .content_below_logo {
    .content_headingText {
      h1 {
        font-size: 24px;
        line-height: 28px;
      }

      h2 {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .content_supporting_text {
      font-size: 16px !important;
      line-height: 20px;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 90%;
    }

    .card_login {
      width: 364px;
      padding: 16px 16px;

      .card_header {
        font-size: 18px;
        margin-bottom: 10px;
      }

      .card_text {
        font-size: 14px;
        line-height: 18px;
      }

      button {
        font-size: 14px;
      }
    }

    .card_right_section {
      h1 {
        font-size: 14px;
      }

      p {
        font-size: 10px;
        line-height: 14px;
      }
    }

    .bottom_section {
      font-size: 10px !important;
      line-height: 12px;
      margin-top: 24px;
    }
  }

}

@media (max-width: 440px) {
  .w-880 {
    width: 400px;
  }
}

@media (max-width: 380px) {
  .w-880 {
    width: 880px;
  }
}