@import './assets/sass/custom.scss';
@import './assets/sass/userManagement.scss';
@import './assets/sass/responsive.scss';
@import './assets/sass/filters.scss';

html, body {
  height: 100%;
  background-color: black;
  margin: 0;
  overflow-x: hidden;
  overflow: -moz-scrollbars-vertical;
  font-family: 'Graphik', sans-serif;
  /* overflow-y: scroll; */
}