.filterbar {
  display: block;

  .filterbar_inner {
    margin-top: 10px;
  }

  .header_searchable {
    width: 150px;
    min-width: 150px;
  }
}

.dashboard_header_main {
  .top_header_filters {
    padding: 10px 0;
    justify-content: right;
  }
}

.search_select_field,
.search_datepicker_field {
  display: flex;
  align-items: center;
  border-right: 1px solid #E6E6E6;

  .search_select_calander_icon {
    img {
      min-width: 18px;
    }
  }

  .header_searchable,
  .header_datepicker {
    min-width: 120px;
    text-align: center;

    &.border-gray-500 {
      border-color: #d1d1d1;
    }

    .ant-select-selector,
    .ant-picker {
      font-family: "Graphik", sans-serif !important;
      border: none !important;
      background-color: transparent !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .ant-select-selection-item,
      .ant-picker-input>input {
        font-size: 18px !important;
        font-weight: 500;
        margin-right: 5px;
        background-color: transparent !important;
        color: #33383f;

        &:focus {
          outline: none;
        }
      }

      .ant-select-selection-placeholder {
        font-size: 18px !important;
      }
    }

    .ant-select-arrow {
      .anticon {
        svg {
          fill: #9d9d9d;
          font-weight: bold;
        }
      }
    }
  }
}

.search_select_field_dark,
.search_datepicker_field {
  display: flex;
  align-items: center;
  border-right: 1px solid #ffffff73;

  .search_select_calander_icon {
    img {
      min-width: 18px;
    }
  }

  .header_searchable,
  .header_datepicker {
    min-width: 120px;
    text-align: center;

    &.border-gray-500 {
      border-color: #d1d1d1;
    }

    .ant-select-selector,
    .ant-picker {
      font-family: "Graphik", sans-serif !important;
      border: none !important;
      background-color: transparent !important;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      .ant-select-selection-item,
      .ant-picker-input>input {
        font-size: 18px !important;
        font-weight: 500;
        margin-right: 5px;
        background-color: transparent !important;
        color: #ffffff;

        &:focus {
          outline: none;
        }
      }
    }

    .ant-select-arrow {
      .anticon {
        svg {
          fill: #9d9d9d;
          font-weight: bold;
        }
      }
    }
  }
}

.my-select .ant-select-selection-overflow {
  flex-wrap: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.my-select .ant-select-selection-overflow-item {
  flex-shrink: 0;
}

.apply_filter_button {
  height: 40px;
  background-color: #0073EE;
  padding: 10px 12px;
  color: #FFFFFF;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  border-radius: 8px;
  margin-left: 8px;

}

.isDisabled {
  background-color: #D4D4D4;
  cursor: not-allowed;
}

.apply_filter_button:active {
  background-color: #005bb5;
  /* Darker shade for the click effect */
  transform: scale(0.98);
  /* Slightly shrink the button */
}

.cancle_filter_button {
  height: 40px;
  padding: 10px 12px;
  color: #1D242E;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E9EB;
}

.dark_btn .cancle_filter_button {
  height: 40px;
  padding: 10px 12px;
  color: #ffffff;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid #E5E9EB;
}

.cancle_filter_button:active {
  background-color: #F0F0F0;
  /* Slightly darker background for the click effect */
  border-color: #C0C0C0;
  /* Darker border for the click effect */
  transform: scale(0.98);
  /* Slightly shrink the button */
}

:where(.css-dev-only-do-not-override-2rgkd4).ant-picker {
  box-sizing: border-box;
  margin: 0px 10px;
  padding: 4px 11px 4px;
  color: rgb(51, 56, 63) !important;
  font-size: 12px !important;
  line-height: 1;
  list-style: none;
  font-family: "Graphik", sans-serif !important;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: transparent !important;
  border-radius: 6px;
  transition: border 0.2s, box-shadow 0.2s;
  width: 221px !important;
}


// .multi_select_field.ant-select-selector {
//   outline: none;
//   color: white;
//   border: none !important;
//   padding: 8px 0px !important;
//   padding-left: 5px !important;
// }

// .multi_select_field.ant-select-arrow,
// .multi_select_field.ant-select-clear {
//   color: white !important;
// }

// .multi_select_field.ant-select-selection-item {
//   background-color: #0073ee !important;
// }

// .multi_select_field.ant-select-selection-item-remove {
//   color: white !important;
// }

// .multi_select_field.ant-select-selection-placeholder {
//   color: #9e9e9e !important;
// }

// .multi_select_field.ant-pagination-options-size-changer .ant-select-selector {
//   background-color: white !important;
// }

// .multi_select_field.ant-pagination-options-size-changer
//   .ant-select-selector
//   .ant-select-selection-item {
//   background-color: white !important;
// }

// multi_select_field.ant-select-selection-placeholder {
//   color: #999999 !important;
//   font-family: "Sora", sans-serif !important;
//   font-size: 16px !important;
// }

// .multi_select_field.activity-mddl .ant-select-selector {
//   background-color: white !important;
//   outline: none;
//   color: black;
//   border: none !important;
//   padding: 0px 0px !important;
//   padding-left: 0px !important;
//   outline: none !important;
// }

// .multi_select_field.multi_select_field .ant-select-focused {
//   outline: none !important;
// }


.crossClass .ant-select-clear {
  top: 35%;
  right: 20%;
  font-size: 18px;
  opacity: 1;
}

.search_date_field_dark :where(.css-dev-only-do-not-override-ccdg5a).ant-picker-outlined {
  background: rgb(103, 103, 103);
  border-color: black;
}

.search_date_field_dark :where(.css-dev-only-do-not-override-ccdg5a).ant-picker {
  color: white;
}

.search_date_field_dark :where(.css-dev-only-do-not-override-ccdg5a).ant-picker .ant-picker-input>input::placeholder {
  color: #e3e3e3;
}