.Login_left_pannel {
  background-image: url("../images/login_left_panel_background1.jpg");
  color: white;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  background-blend-mode: overlay;
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.75);
}

.login_left_panel_initial {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
}

.login_left_panel_internal_box {
  width: calc(100% - 60px);
}

.logo_and_text {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  width: max-content;
  margin-bottom: 44px;

  img {
    width: 80px;
    height: 80px;
  }
  .text {
    img {
      width: 280px;
      height: 80px;
    }
  }
}

.content_below_logo {
  .content_headingText {
    width: max-content;
    h1 {
      font-size: 46px;
      line-height: 52px;
    }
    h2 {
      font-size: 46px;
      line-height: 60px;
      background: linear-gradient(to right, #10d6fe 0%, #0073ee 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .content_supporting_text {
    color: #93a2b3;
    font-size: 20px !important;
    line-height: 28px;
    margin-top: 12px;
    margin-bottom: 42px;
    width: 450px;
  }
  .card_login {
    background-color: #111f2c;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.16);
    width: 384px;
    padding: 20px 20px;
    .card_header {
      font-size: 28px;
      margin-bottom: 16px;
      line-height: 100%;
      font-weight: 600;
    }
    .card_text {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px;
      color: #93a2b3;
    }
    button {
      border-radius: 4px;
      background-color: #e07900;
      padding: 8px 16px;
    }
  }

  .card_right_section {
    padding-left: 24px;
    h1 {
      font-size: 24px;
      line-height: 100%;
    }
    p {
      font-size: 16px;
      margin-top: 8px;
      line-height: 20px;
      color: #93a2b3;
    }
  }
  .bottom_section {
    color: #93a2b3;
    font-size: 20px !important;
    line-height: 28px;
    margin-top: 52px;
  }
}

.heading{
  font-family: Graphic !important;
}

.supporting_text{
  font-family: 'Sora', sans-serif !important;
}

.user-profile-image {
  .upload-button {
    width: 100px;
    margin-top: 10px;
  }
}
