.bg_img_height {
  height: 1500px;
  margin-top: -340px;
}

.paragraphs_font {
  font-family: "Sora", sans-serif;
}

.para_color {
  color: #93a2b3;
}

.link-text {
  color: #168de9;
}

.bg-dark-theme {
  background-color: #000b15;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  color: wheat;
  background-color: #ff8a00;
}

.custom-csv-upload {
  display: inline-block;
  cursor: pointer;
  color: wheat;
  background: linear-gradient(90deg,
      rgba(16, 171, 254, 1) 30%,
      rgba(0, 115, 238, 1) 90%);
}

// select {
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   background: transparent;
//   background-image: url("../arrow_select.svg");
//   background-repeat: no-repeat;
//   background-position-x: 95%;
//   background-position-y: 55%;
// }

.language-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../small-arrow.svg");
  background-repeat: no-repeat;
  background-position-x: 92%;
  background-position-y: 15px;
}

.language-select-black-icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 8px;
}

.gradient_button {
  // background: linear-gradient(
  //   90deg,
  //   rgba(16, 171, 254, 1) 30%,
  //   rgba(0, 115, 238, 1) 90%
  // );
  background: #0073EE;
}

.greetings {
  border-left: 2px solid;
  border-image: linear-gradient(to bottom,
      rgba(16, 171, 254, 1),
      rgba(0, 115, 238, 1)) 1 100%;
}

.text_gold {
  color: #e07900;
}

.button_gold {
  background-color: #e07900;
}

.select-box {
  height: 50px;
}

.gradient_text {
  background: linear-gradient(to right, #10abfe 1%, #0073ee 99%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.lets_go_text {
  background: linear-gradient(to right, #10abfe 12%, #0073ee 40%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.opacity_blue {
  background-color: #111f2c;
}

.field_bg-blue {
  background-color: #000b15;
}

.inputfield {
  font-weight: 400;
  width: 100%;
  height: 58px;
  font-family: "inter", sans-serif;
  background-color: #000b15;
  border: 1px solid #1e293b;
  border-radius: 6px;
}

.was-validated {
  border: 1px solid red !important;
}

.was-validated::-webkit-input-placeholder {
  color: red;
}

.border {
  border: 1px solid #1e293b;
}

.button-border {
  border: 1px solid #1e293b;
}

.text-area {
  border: 1px solid #1e293b;
}

.PhoneInput {
  height: 45px;
  width: 100%;
  font-size: 16px;
  background-color: #000b15;
  border: 1px solid #1e293b;
}

.PhoneInputInput {
  height: 43px;
  width: 100%;
  background-color: #000b15 !important;
  outline: none;
  color: white;
}

.PhoneInputCountry {
  font-size: 18px;
  border-right: 1px solid #1e293b;
  padding-right: 10px;
  padding-left: 10px;
}

.PhoneInputCountryIconUnicode,
.PhoneInputCountryIcon {
  color: white;
}

.PhoneInputCountrySelectArrow {
  color: white;
}

.input_select {
  width: 70%;
  height: 30px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: block;
  background: url("../call.svg") no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  margin-top: 8px;
}

.landing-screen {
  background: url("../landing_page_bg.png") no-repeat;
  width: 100%;
  height: 702px;
  display: block;
  background-size: cover;
}

.dashboard_card_main {
  display: flex;
  height: 152px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s;
}

.dashboard_card_main:hover {
  transform: translate(0, -8px);
  box-shadow: 0 0 11px rgba(35, 87, 160, 0.7);
}

.dashboard_card_bg_color {
  color: white;
  background-color: #091724;
}

.dashboard_card_inner_right {
  padding: 0px 0px 0px 20px;
}

.card_title {
  font-size: 26px;
  font-weight: 700;
}

.card_description {
  font-size: 13px;
}

.user_registration_inner {
  display: flex;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.left_bordered {
  height: 50px;
  border-left: 1px solid #1e293b;
  margin-left: 15px;
}

.tabs_right {
  padding-left: 30px;
}

.right-position {
  right: -760px;
}

.w-99 {
  width: 75%;
}

.w-200 {
  width: 500px;
  height: auto;
}

.animated {
  -webkit-animation: rotate 550s normal linear infinite;
  animation: rotate 550s normal linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }

  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }

  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.bg_color {
  background-color: #00080f;
}


.ant-table-wrapper .ant-pagination .ant-pagination-item {
  margin: 0 4px !important;
  background-color: transparent !important;
  font-family: "Sora", sans-serif;
}

.ant-table-wrapper .ant-pagination .ant-pagination-item-active {
  font-weight: bold !important;
  border: none !important;
  border-bottom: 2px solid #0073ee !important;
  border-radius: 0px;
  height: 46px;
  padding-top: 8px;
  background-color: transparent !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  font-size: 14px !important;
  margin: 0 44px !important;
}

.dark-theme .ant-pagination-item-link,
.dark-theme .ant-pagination-prev,
.dark-theme .ant-pagination-next {
  background-color: #000b15 !important;
}

.light-theme .ant-pagination-item-link,
.light-theme .ant-pagination-prev,
.light-theme .ant-pagination-next {
  background-color: #fff !important;
}

.table_bg_color_black_th th {
  background-color: #000b15 !important;
  color: white !important;
  font-family: "Graphik", sans-serif !important;
  font-size: 16px !important;
  padding: 16.5px !important;
  line-height: 100% !important;
}

.table_bg_color_black_td td {
  background-color: #000b15 !important;
  color: white !important;
  font-family: "Sora", sans-serif !important;
  font-size: 16px !important;
  padding: 15.5px 16.5px !important;
  line-height: 100% !important;
}

.table_rows_black .ant-table-row>td {
  background-color: #091724 !important;
  color: white !important;
}


.table_rows_black .ant-table-row>td {
  background-color: #000b15 !important;
  border-top: 1px solid #1e293b;
  background-color: #000b15 !important;
  border-top: 1px solid #1e293b;
  color: white !important;
}

.table_bg_color_white_th th {
  background-color: #ffffff !important;
  color: #1d242e !important;
  font-family: "Graphik", sans-serif !important;
  font-size: 16px !important;
  padding: 16.5px !important;
  line-height: 100% !important;
}

.table_bg_color_white_td td {
  background-color: #ffffff !important;
  color: #1d242e !important;
  font-family: "Sora", sans-serif !important;
  font-size: 16px !important;
  padding: 15.5px 16.5px !important;
  line-height: 100% !important;
}

.table_rows_white .ant-table-row>td {
  background-color: white !important;
  color: black !important;
  border-bottom: 1px solid black !important;
}

.table_rows_black .ant-table-placeholder>td {
  background-color: #091724 !important;
}

.table_rows_black .ant-table-placeholder>td .ant-empty-description {
  color: white !important;
}

.tabs_bg-white {
  background-color: #e3f2fe;
  color: #0073ee;
}

.bg-for-light {
  background-color: #fff;
}

.bg-import-button {
  background-color: #1e3a2c;
}

.bg-add-new-user-button {
  background-color: #5a4123;
}

.bg-add-new-company-button {
  background-color: #0c2541;
}

.bg-light-blue-button {
  background-color: #0073ee;
}

.bg-light-green-button {
  background-color: #20744a;
}

.modal-container-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 300;
}

.summary-container {
  background-color: rgba(0, 11, 21, 0.7);
}

.card-board {
  border: 1px solid #e5e9eb;
}

.border-right-only {
  border-right: 1px solid #e5e9eb;
}

.border-dashed {
  border: 1px dashed #e5e9eb;
}

.landign-screen-text-animated {
  background: linear-gradient(to right,
      rgba(16, 171, 254, 1) 20%,
      rgba(0, 115, 238, 1) 30%,
      rgba(16, 171, 254, 1) 70%,
      rgba(0, 115, 238, 1) 80%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 300% auto;
  animation: textShine 1s ease-in-out infinite alternate;
}

@keyframes textShine {
  0% {
    background-position: 0% 20%;
  }

  50% {
    background-position: 0% 40%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.button-dark {
  background-color: #091724;
}

.input_field_main_password {
  position: relative;
}

.show_password {
  right: 10px;
}

input::-webkit-input-placeholder {
  color: #9e9e9e;
}

.box-shadow-sign-in {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}

.input_field_main_textarea textarea {
  background: #000b15;
  border: 1px solid #1e293b;
  outline: none;
  color: white;
  resize: none;
}

.box_1 {
  width: 55px;
  height: 0px;
}

input[type="checkbox"].switch_1 {
  font-size: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2.8em;
  height: 20px;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #0ebeff;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: -3px;
  top: -3.4px;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1.4em);
}

.checkBox_value_color {
  color: #e92f46;
}

.multi_select_field.ant-select-selector {
  outline: none;
  color: white;
  border: none !important;
  padding: 8px 0px !important;
  padding-left: 5px !important;
}

.multi_select_field.ant-select-arrow,
.multi_select_field.ant-select-clear {
  color: white !important;
}

.multi_select_field.ant-select-selection-item {
  background-color: #0073ee !important;
}

.multi_select_field.ant-select-selection-item-remove {
  color: white !important;
}

.multi_select_field.ant-select-selection-placeholder {
  color: #9e9e9e !important;
}

.w-35 {
  width: 35.666667%;
}

.w-49 {
  width: 49%;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(32, 32, 32);
  background: #01111f;
  /* border-radius: 10px; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0073ee !important;
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0073ee;
}

/* Horizontal scrollbar */
::-webkit-scrollbar:horizontal {
  height: 7px;
  /* height of horizontal scrollbar */
}

/* Track for horizontal scrollbar */
::-webkit-scrollbar-track:horizontal {
  background: #01111f;
  /* color of the track for horizontal scrollbar */
}

/* Handle for horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal {
  background: #0073ee;
  /* color of the thumb for horizontal scrollbar */
}

/* Handle on hover for horizontal scrollbar */
::-webkit-scrollbar-thumb:horizontal:hover {
  background: #0056b3;
  /* color of the thumb on hover for horizontal scrollbar */
}

:where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background: #0073ee;
}

.ant-table-wrapper.light-theme {
  border: 1px solid #e5e9eb !important;
  /* Border color for light theme */
  border-radius: 8px !important;
  /* Optional: Add border radius for rounded corners */
  overflow: hidden !important;
  /* Ensure content does not overflow the border */
}

/* Dark theme: Add border around the entire table */
.ant-table-wrapper.dark-theme {
  border: 1px solid #1e293b !important;
  /* Border color for dark theme */
  border-radius: 8px !important;
  /* Optional: Add border radius for rounded corners */
  overflow: hidden !important;
}

:where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-2rgkd4).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  width: 0px !important;
}

/* .draggable-table .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .ant-table-thead .ant-table-cell{
  background: white!important;
} */
.ant-table-expanded-row .ant-table-cell {
  padding: 0px !important;
}

.tabs-bg {
  /* background-color: rgb(229, 242, 255); */
  background-color: #fcfcfc;
}

.active-tab {
  border-bottom: 2px solid #0073ee;
}

.tab-pannel {
  border-left: 2px solid #0073ee;
}

input[type="checkbox"].switch_11 {
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 3em;
  height: 1.5em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_11:checked {
  background: #11c700;
}

input[type="checkbox"].switch_11:after {
  position: absolute;
  content: "";
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

input[type="checkbox"].switch_11:checked:after {
  left: calc(100% - 1.5em);
}

.z-700 {
  z-index: 700;
}

.z-800 {
  z-index: 800;
}

.side-navbar {
  width: 0;
  overflow-x: hidden;
}

.width-250 {
  width: 300px;
  animation: widthIncreasae 0.5s ease-in-out;
}

@keyframes widthIncreasae {
  0% {
    width: 0px;
  }

  100% {
    background-position: 300px;
  }
}

.side-navbar a {
  transition: 1s;
}

.custom-h-10 {
  height: 10%;
}

.custom-h-90 {
  height: 90%;
}

.f-18 {
  font-size: 18px;
}

.tabs-input-border {
  border: 1px solid #e6e6e6;
  width: 300px;
  outline: none;
}

.tabs-select-border {
  border: 1px solid #e6e6e6;
  width: 300px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../down.png");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 10px;
}

.opt-input {
  border: 1px solid #8b9caa;
  width: 200px;
  outline: none;
}

.cond-select-icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 7px;
}

/* .ant-table-pagination{
    width: 30%;
    margin: 15px auto!important;
  } */
.ant-pagination li {
  background-color: white !important;
}

.ant-pagination-options-size-changer {
  border: 1px solid #8b9caa !important;
}

.multi_select_field.ant-pagination-options-size-changer .ant-select-selector {
  background-color: white !important;
}

.multi_select_field.ant-pagination-options-size-changer .ant-select-selector .ant-select-selection-item {
  background-color: white !important;
}

.side-navbar-item:hover {
  background-color: #111f2c;
}

.uplaod-scvicon {
  animation: MoveUpDown 5s linear infinite;
}

@keyframes MoveUpDown {

  0%,
  100% {
    transform: translateY(-0.5rem);
  }

  50% {
    transform: translateY(1ch);
  }
}

.heading-light-gray {
  color: #9e9e9e;
}

// select:invalid,
// select option[value=""] {
//   color: #999999;
//   font-family: "Sora", sans-serif;
// }

.multi_select_field.ant-select-selection-placeholder {
  color: #999999 !important;
  font-family: "Sora", sans-serif !important;
  font-size: 16px !important;
}

.mrt-2 {
  margin-top: -2px;
}

.row-reverse {
  flex-direction: row-reverse !important;
}

.pl-10 {
  padding-left: 10px;
}

.w-11 {
  width: 11%;
}

.inputfield-white {
  font-weight: 300;
  width: 100%;
  height: 45px;
  font-family: "Sora", sans-serif;
}

.test:after {
  content: "\2807";
  font-size: 30px;
}

.mobile-view {
  border: 4px solid black;
  border-radius: 8px;
}

.golden-view {
  border: 4px solid rgb(114, 114, 113);
  border-radius: 6px;
}

.head-border {
  border-top-left-radius: 2px 2px;
  border-top-right-radius: 2px 2px;
}

.activity-input-white {
  font-weight: 300;
  width: 100%;
  height: 30px;
  font-family: "Sora", sans-serif;
}

.acitivity-form-inputblack {
  font-weight: 300;
  width: 100%;
  height: 30px;
  font-family: "Sora", sans-serif;
  background-color: #000b15;
  border: 1px solid #1e293b;
}

.activiy-select-black {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 7px;
}

.activiy-select-gray {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../sel_gray.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 9px;
}

:where(.css-dev-only-do-not-override-2rgkd4).ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 8px 0px 0px 0px;
}

.ant-collapse-expand-icon {
  margin-top: 5px;
}

.activity-mddl {
  padding: 0px;
}

.multi_select_field.activity-mddl .ant-select-selector {
  background-color: white !important;
  outline: none;
  color: black;
  border: none !important;
  padding: 0px 0px !important;
  padding-left: 0px !important;
  outline: none !important;
}

.edit-button {
  background-color: #f6f6f6;
}

.boarder-description {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.org-details div:nth-child(3) p:nth-child(1) {
  border-right: 1px solid #e6e6e6;
}

.org-details div:nth-child(6) p:nth-child(1) {
  border-right: 1px solid #e6e6e6;
}

.org-details div:nth-child(1) p:nth-child(2) {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.org-details div:nth-child(3) p:nth-child(2) {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
}

.org-details div:nth-child(5) p:nth-child(2) {
  border-bottom: 1px solid #e6e6e6;
}

.org-details div:nth-child(4) p:nth-child(2) {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.org-details div:nth-child(6) p:nth-child(2) {
  border-right: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.site-form div:nth-child(5) {
  width: 100%;
}

.border-bottom {
  border-bottom: 1px solid #091724 !important;
}

.light-theme .css-dev-only-do-not-override-2rgkd4.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: none !important;
}

.light-theme .css-dev-only-do-not-override-2rgkd4.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 1px solid #e5e9eb !important;
  /* Light theme border color */
}

.dark-theme .css-dev-only-do-not-override-2rgkd4.ant-table-wrapper .ant-table-tbody>tr>td {
  border-bottom: 1px solid #1e293b !important;
  /* Dark theme border color */
}

.icon-white {
  color: white;
}

.ant-descriptions-item-label {
  color: white;
  /* border-width: 2px; */
}

.ant-descriptions {
  background-color: white;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  border-bottom: none;
}

.ant-descriptions-bordered {
  color: yellow;
}

.bordered-descriptions-content .ant-descriptions-item-content {
  /* padding: 8px 24px;
  width: 100%;
  height: 60px;
  top: 0 !important;
  bottom: 0 !important;
  background-color: #091724;
  color: white;
  border: 1px solid #1E293B;
  box-sizing: border-box;
  margin-bottom: -17px !important; */

  padding-left: 24px;
  width: 100%;
  height: 100%;
  height: 100%;
  top: 120px;
}

.bordered-descriptions-label .ant-descriptions-item-label {
  /* border: 4px solid #1E293B;
  border-width: 4px solid;
  /* padding-top: 8px; */
  /* padding-left: 24px; */
  /* padding: 4px 24px;
  border-radius: 2px;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  opacity: 1;
  color: white;
  height: 60px;
  background-color: #091724;
  margin-bottom: -17px !important; */
  border: 1px solid #e8e8e8;
  /* Add border inside each description item */
  border-width: 4px solid;
  padding-top: 8px;
  padding-left: 24px;
  width: 100%;
  height: 60px;
  background-color: #f0f0f0;
  top: 20px;
  color: #000000;
}

.ant-descriptions-row {
  padding: 0;
  margin: 0;
}

.multi_select_field.ant-select-focused {
  outline: none !important;
}

.move-to-right {
  transform: translateX(18px);
  transition: linear 0.2s;
}

.move-to-left {
  transform: translateX(-3px);
  transition: linear 0.2s;
}

.edit-button-dark {
  background-color: #0073ee !important;
}

.view-button-dark {
  background-color: #318ce7 !important;
}

.z-index-breads {
  z-index: 99999 !important;
}

.select_box_field {
  width: 100%;
  height: 45px;
  border-radius: 2px;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  // color: #ffffff73;
  // background-color: #000b15;
}

.select_bg {
  background-color: #000b15;
}

.login_inputs {
  width: 525px;
}

.dark_toogle {
  width: 270px;
}

.bread-board {
  border: 1px solid #e6e6e6;
  border-left: none;
}

.dashboard_bread_bg_color {
  color: white;
  background-color: #091724;
  border-left: none;
}

.w-350 {
  width: 350px;
}

.w-53 {
  width: 53%;
}

.w-30 {
  width: 30%;
}

.reporting_wrapper {
  display: "flex";
  justify-content: "space-between";
  // marginBottom: 16;
}

.access_deneid_main {
  background-color: white;
  height: 100%;
  width: 100%;
}

.access_deneid_h1 {
  font-family: "Graphik", sans-serif !important;
  font-size: 65px !important;
  color: "#1D242E";
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.loader {
  border: 5px solid #eaf0f6;
  border-radius: 100%;
  border-top: 5px solid #0073ee;
  animation: spinner 3s linear infinite;
  width: 75px;
  height: 75px;

  &.lg {
    width: 75px;
    height: 75px;
  }

  &.sm {
    width: 35px;
    height: 35px;
  }
}

.animated-text {
  color: #0073ee;
  font-size: 20px;
  margin-left: 10px;
}

.loading-dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-dots {
  text-align: center;
  font-size: 20px;
  margin-left: 5px;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #0073ee;
  border-radius: 50%;
  margin-right: 8px;
  opacity: 0;
  animation: fadeIn 1s infinite linear;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

.map_toggle_button {
  width: 122px;
  height: 40px;
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;

  .single_button {
    width: 56px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.reporting_tooltip_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  h4 {
    color: #1d242e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    font-family: "Graphik", sans-serif;
  }

  h5 {
    color: #6f767e;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    font-family: "Sora", sans-serif;
  }
}

.salesPitchDownloadButton {
  width: 366px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #d7eeff;
  color: #0073ee;
  border-radius: 8px;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin: 16px 0px;
}

.sales_pitch_downlaod_table {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.csvButtonRepoting {
  font-family: "Graphik", sans-serif;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  width: 133px;
  border: 1px solid #dde2e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.selectColumnsButton {
  font-family: "Graphik", sans-serif;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  width: 121px;
  border: 1px solid #dde2e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.csvButtonRepoting.disabled,
.selectColumnsButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  filter: grayscale(100%);
}

.text-button {
  border: none;
  background: none;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  padding: 0;
  margin: 0;
}

.ant-table-measure-row {
  display: none;
  /* Hide the measure row */
}

.link_button {
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  color: #0073ee;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  padding: 10px;
}

.link_button:hover {
  color: #0053ce;
}

.group-container-selected-fields {
  border: 2px dashed #1d242e;
  position: relative;
  gap: 8px;
  margin-top: 32px;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
}

.selected-field {
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  padding: 8px 12px;
  transition: all 0.3s ease;
  background-color: rgba(9, 88, 224, 0.7);
  border-radius: 8px;
  position: relative;
  color: #ffffff;
}

.sales-pitch-form-main-container {
  background: #fff;
  border: 1px solid #e6e6e6;
  padding: 24px 20px 0px 20px;
}

.sales-pitch-form-buttons {
  margin-top: 32px;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}

.border-radius-input-field {
  border-radius: 4px !important;
  transition: box-shadow 0.3s;
}

.border-radius-input-field:focus {
  box-shadow: 0px 0px 0px 3px rgba(0, 115, 238, 0.22);
}

.add_keyword_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
  gap: 6px;
  font-size: 16px;
  font-family: "Sora", sans-serif;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 10px;
}

.save_keyword_button {
  background-color: #0073ee;
  padding: 8px 69px;
  color: #ffffff;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
}

.cancle_keyword_button {
  padding: 8px 69px;
  color: rgba(255, 255, 255, 0.5);
  background-color: #091724;
  font-family: "Graphik", sans-serif;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid #091724;
}

/* Custom styles for Rating component */
.rating-container {
  margin-bottom: 1rem;
}

.rating-label {
  display: block;
  margin-bottom: 0.5rem;
  color: white;
}

.stars {
  display: flex;
}

.star {
  font-size: 2rem;
  color: #ccc;
  cursor: pointer;
  position: relative;
}

.star.filled {
  color: gold;
}

.star.half-filled::before {
  content: "★";
  position: absolute;
  left: 0;
  width: 50%;
  overflow: hidden;
  color: gold;
}

.single-Tab {
  border-bottom: 2px solid #0073ee;
}

.scrollable {
  height: 100%;
  overflow-y: scroll;
  border: 1px solid black;
  padding: 10px;
  margin-right: 10px;
  right: 0;
  top: 0;
  transition: right 0.3s ease-in-out !important;
}

.user-avatar {
  height: 100px;
  width: 100px;
}

.upload-user-image-container {
  width: 88px;
  height: 88px;
  background-color: #1d242e;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-user-image {
  width: 128px;
  height: 128px;
}

.image-conatiner {
  width: 88px;
  height: 88px;
  position: relative;
  border-radius: 50%;
}

.image-conatiner .user-image {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.delete-image {
  width: 115px;
  height: 115px;
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.attedace-image {
  width: 125px;
  height: 125px;
  position: relative;
  border-radius: 4px;
}

.image-input-info {
  width: 70%;
}

.calling-button {
  color: white;
  width: 290px;
  height: 40px;
  background-color: #00835b;

  margin-left: 20px !important;
  padding-bottom: 5px;
  padding-top: 15px;
  // padding: 15px;
}

.end-calling {
  color: white;
  width: 290px;
  height: 40px;
  background-color: #e71d36;
  // padding-right: 10px;
  // padding-left: 10px;
  // padding: 15px;
  margin-left: 20px !important;
  padding-bottom: 5px;
  padding-top: 15px;
}

.text-line {
  stroke-dasharray: 500;
  stroke-dashoffset: 500;
  animation: dash 3s linear infinite, filling 3s ease-in infinite;
  font-size: 80px;
}

.text-line text {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes filling {
  0% {
    fill: #10abfe;
    fill-opacity: 0;
  }

  70% {
    fill: transparent;
    fill-opacity: 0.3;
  }

  85% {
    fill: #0073ee;
    fill-opacity: 0.8;
  }

  100% {
    fill: #0073ee;
    fill-opacity: 1;
  }
}

.w-siderbar {
  width: 64px;
}

.icon-container {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: #1d242e;
}

.w-880 {
  width: 880px;
}

.title-content {
  color: #667085;
}

.modal-title-container {
  border-bottom: 1px solid rgba(228, 228, 228, 0.2);
}

.instructions-list {
  color: #667085;
  font-size: 12px;
  padding-left: 20px;
}

.w-400 {
  width: 400px;
}

.input-field-container {
  .group {
    >div[data-focus="true"] {
      >label {
        color: #52525b !important;
      }
    }

    div:first-child {
      background-color: #000b15 !important;
      color: white;
      border: 1px solid #1e293b;
      height: 58px;
      border-radius: 6px;

      label {
        font-size: 14px;
        color: white;
      }
    }

    div:nth-child(2) {
      input {
        background-color: #000b15;
        color: white !important;
        outline: none;
        font-size: 14px;
      }
    }
  }
}

.select_field_container {
  div:first-child {
    .group {
      div:nth-child(2) {
        button {
          background-color: #000b15 !important;
          color: white;
          outline: none !important;
          font-size: 14px !important;
          border: 1px solid #1e293b;
          border-radius: 6px !important;
          height: 58px !important;
        }

        button {
          div:nth-child(2) {
            span {
              color: white;
            }
          }

          div:nth-child(1) {
            span {
              color: white;
            }
          }
        }

        >button {
          >label {
            color: #ECEDEE !important;
          }
        }

        >button[data-focus="true"] {
          >label {
            color: #52525b !important;
          }
        }
      }
    }
  }
}

.field-label {
  color: #ecedee;
}

.field-focused {
  color: #52525b;
  border: 1px solid #0073EE;
  border-radius: 6px;

  // font-size: 12px !important;
}

// .field-focused > div {
//   --tw-ring-offset-width : 0px  
//   // font-size: 12px !important;
// }

.input-Datefield-container {
  > :first-child {
    > :first-child {
      background-color: #000b15 !important;
      color: white;
      border: 1px solid #1e293b;
      height: 58px !important;
      border-radius: 6px !important;

      > :first-child {
        svg {
          border: none !important;
        }
      }

      > :nth-child(2) {
        > :first-child {
          div {
            color: white !important;
          }
        }
      }
    }
  }
}

.font-graphik-important {
  font-family: "Graphik", sans-serif !important;
}

.ant-table-thead>tr>th.custom-header {
  background-color: #e3f2fe !important;
  color: #0073ee !important;
  position: relative;
}

.ant-table-thead>tr>th.custom-header>div {
  background-color: #e3f2fe;
  color: #0073ee !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/* Custom Table */
.custom-checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #e5e9eb;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.custom-checkbox:checked {
  background-color: #1890ff;
  border: 1px solid #1890ff;
}

.custom-checkbox:checked::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 43%;
  width: 6px;
  height: 10px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: translate(-50%, -50%) rotate(45deg);
}

.custom-checkbox-all {
  position: relative;
  width: 16px;
  height: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #e5e9eb;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}

.custom-checkbox-all:checked {
  background-color: #1890ff;
}

.custom-checkbox-all:checked::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 3px solid white;
}

.accordionClass {
  height: 56px;
  //max-height: 56px; /* Set the same initial max-height */
  overflow: hidden;
  /* Hide any overflowing content during transition */
  transition: max-height 2s ease;
}

.accordionClass[data-open="true"] {
  height: auto;
  /* Set a large enough max-height to accommodate the expanded content */
  margin-bottom: 10px;
  /* No need to specify transition again, it's inherited from the initial state */
}

.accordionClass h2>button {
  padding: 0 !important;
  height: 56px;
}

.accordionClass[data-open="true"] {
  section>div.py-2 {
    padding: 0 !important;
  }
}

/* Custom Table */

.outline-none {
  outline: none !important;
}

.outline-input-custom {
  background-color: #000b15;
  border: 1px solid #1e293b;
  border-radius: 5px;
  color: #ffffff;
}

.outline-input-custom>div {
  background-color: transparent;
}

.calender-icon>div>div>button {
  margin-top: -16px;
}

.calender-icon>div>span {
  color: #ffffff;
}

input:-webkit-autofill {
  // -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: #FFF;
  /* Change this to your desired color */
}

.custom-textarea>div:hover {
  background-color: transparent !important;
}

.custom-textarea>div {
  background-color: transparent !important;
}

.custom-textarea>div>label {
  color: #ffffff !important;
}

.custom-textarea>div[data-focus="true"]>label {
  color: #52525b !important;
}

.custom-textarea>div>div>textarea {
  color: #ffffff !important;
}

.input:-webkit-autofill {
  font-size: 1rem;
  background-color: transparent !important;
}

.autofill {
  transform: translateY(-1rem);
  /* Adjust to ensure placeholder doesn't overlap */
  color: black;
  /* Or any color that makes it readable */
}

.customMultiSelect:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

.customMultiSelect.ant-select-focused:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  box-shadow: none !important;
}

.customMultiSelect:where(.css-dev-only-do-not-override-ccdg5a).ant-select .ant-select-selection-placeholder {
  color: black !important;
}

.dds_Dashboard .bg-default-100 {
  background: #fff;
  box-shadow: none;
}

.dds_Dashboard_dark .bg-default-100 {
  background: #000;
  box-shadow: none;
}

.swiper-button-prev,
.swiper-button-next {
  position: relative !important;
  width: fit-content !important;
  display: inline-block !important;
  color: black !important;
  margin-left: 10px !important;
  margin-top: 8px !important;
  height: auto !important;
  right: auto !important;
  left: auto !important;
  top: -45px !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
}

.swiper-pagination {
  float: left !important;
  margin-left: 20px !important;
  width: fit-content !important;
  font-size: 20px !important;
  margin-top: 10px !important;
  position: relative !important;
  display: inline-block !important;
  top: -50px !important;
  left: auto !important;
  right: auto !important;
}

.force-scrollbar {
  overflow-y: scroll !important;
  height: calc(130vh - 150px) !important;
  /* Adjust height as needed */
}

.force-scrollbar::-webkit-scrollbar {
  width: 2px;
}

.force-scrollbar::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 10px;
}


.progressbar {
  background-color: #eee;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  border-radius: 20px;
  overflow: hidden;
  height: 10px;
  width: 100%;
}



.progress {
  animation: progress 2s infinite linear;
}

.left-right {
  transform-origin: 0% 50%;
}

@keyframes progress {
  0% {
    transform: translateX(0) scaleX(0);
  }

  25% {
    transform: translateX(0) scaleX(0.4);
  }

  50% {
    transform: translateX(100%) scaleX(0.5);
  }

  75% {
    transform: translateX(100%) scaleX(-0.4);
  }

  100% {
    transform: translateX(0) scaleX(0);
  }
}

.search_select_field_dark :where(.css-dev-only-do-not-override-ccdg5a).ant-select .ant-select-selection-placeholder {
  color: #e1e1e1;
}

.search_select_field_dark :where(.css-dev-only-do-not-override-ccdg5a).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-content {
  color: white;
}

.search_select_field_dark :where(.css-dev-only-do-not-override-ccdg5a).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove {
  color: white;
}

.custom_dark_multi:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  background: black;
}

.custom_dark_multi:where(.css-dev-only-do-not-override-ccdg5a).ant-select .ant-select-selection-placeholder {
  color: white;
}


.draggable_dark_theme:where(.css-dev-only-do-not-override-ccdg5a).ant-table-wrapper .ant-table-thead>tr>th {
  color: white;
  background: #424242;
}

.ant-select-focused:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: transparent !important;
  box-shadow: none !important;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
}

:where(.css-dev-only-do-not-override-ccdg5a).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: transparent !important;
}


.search_select_field_dark :where(.css-dev-only-do-not-override-142vneq).ant-select .ant-select-selection-placeholder {
  color: rgb(185, 185, 185);
}

.search_select_field_dark :where(.css-1kf000u).ant-select .ant-select-selection-placeholder{
  color: rgb(185, 185, 185);
}


.search_select_field_dark .ant-select-selection-placeholder{
  color: rgb(185, 185, 185) !important;
}

.group[data-has-helper="true"] .group-data-\[has-helper\=true\]\:flex{
  display: none;
}

/*Hold Multiline for multi select*/
.customMultiSelect .ant-select-selector {
  display: flex;
  flex-wrap: nowrap;         
  overflow-x: auto;          
  overflow-y: hidden;        
  white-space: nowrap;       
  align-items: center;       
  height: auto !important;   
  padding: 4px 8px;          
}

.customMultiSelect .ant-select-selection-overflow {
  display: flex;
  flex-wrap: nowrap;         
}

.customMultiSelect .ant-select-selection-item {
  white-space: nowrap;       
  margin-right: 8px;         
}

.customMultiSelect .ant-select-selector::-webkit-scrollbar {
  height: 4px;               
  display: none;             
}

.customMultiSelect .ant-select-selector::-webkit-scrollbar-thumb {
  background-color: #ccc;  
  border-radius: 2px;       
}

.customMultiSelect .ant-select-selector:hover::-webkit-scrollbar {
  display: block;            
}

.customMultiSelect .ant-select-selector::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;    
}


.ant-select-selector{
  border-radius: 0px !important;
  border: 0px !important;
}

.ant-select-selection-wrap{
  align-self: center !important;
}

.custom_dark_multi .ant-select-selector{
  background: black !important;
}


.custom_dark_multi .ant-select-selection-item{
  background: gray !important;
}


.custom_dark_multi .ant-select-selection-placeholder{
  color: gray !important ;
}